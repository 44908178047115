
import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L1.scss';

export default class B11U1L1 extends Component {

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

 
    return (

      // <div class="contenedor"> 
 
<Grid style={{ backgroundColor: '#101010'}}>

  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
    <>
    <h2 className="lesson1title"><br></br>Lesson 1</h2>
    <Slider {...settings}>
    <div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU7ES%2F4d48965c-360f-42a5-9833-45ec2c6be412?alt=media&token=8c376f86-2909-4862-9778-19788df8fb64"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU7ES%2F386f745d-53ac-401d-8551-6510e28cd651?alt=media&token=5a75a1fb-ce5c-4a4a-a564-b6c7db7f883a"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU7ES%2Fe665d9d6-9897-4f2f-8a6a-0bb4ec7dc1d0?alt=media&token=267cb95e-fda0-42ea-bc31-8a2418d1cee9"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU7ES%2Fe26bd469-52fe-4e56-8b13-c4edd2c83976?alt=media&token=982c828b-44d6-45f8-9f77-ec6e2219cd7a"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU7ES%2F0dd81545-9c4a-4d64-afba-de171ce36c5b?alt=media&token=8e33df55-40ed-4dc4-807e-feda47b75ed1"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU7ES%2F6d661a3d-57b8-4c59-911d-76311ff40744?alt=media&token=722cc8f8-c219-4e4d-9474-4f8e106472aa"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU7ES%2F74eb6fda-88ac-4d4e-a12a-2a116043c8e5?alt=media&token=32f200ff-311b-458c-9d7d-1ebacef60fe5"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU7ES%2F9ee62cf6-d2a9-4cb7-9442-11e875642bd7?alt=media&token=3fd01957-f717-47a5-bc18-ab1c06147102"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU7ES%2F89b13142-56ea-42a2-9198-ef2427f157f0?alt=media&token=2d5debcc-cf67-4304-b225-4fed9e951dc1"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU7ES%2Fddb124ac-04fe-4da7-94cc-9808569722a7?alt=media&token=9f5dda7e-498e-4257-91c9-9a886235a537"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU7ES%2Fe2b986b4-24ec-4858-92b1-dd335ca060a8?alt=media&token=067d14b1-2b77-4f0e-bf32-8ea6f2e06470"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU7ES%2F0ad30d3a-83d5-45c1-a124-b803c2fd83e6?alt=media&token=959a7ed0-9d2c-4d3c-9f60-83c3ed7d91a3"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU7ES%2F1dd10c72-d6bd-47b4-99fb-0607d42618e5?alt=media&token=d33b2e0c-4628-49c3-83fe-ab444e91588a"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU7ES%2F07693121-ab48-4f02-866d-a8d647f5aac9?alt=media&token=99d4c97c-b719-4e5b-8fb4-11343a3d8ba8"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU7ES%2Fccbaf71d-6391-4eb9-ae9b-bbbc0a954019?alt=media&token=ec1fef86-4d74-4d88-9c8c-f9afa9fdd56b"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU7ES%2Fec334297-e2a8-4cab-92c9-d02678c8c07d?alt=media&token=cfd7f866-6408-4b3a-a41a-48c8af4385ff"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU7ES%2F816d2fc0-54fc-4c1d-bd60-ed33f91776c4?alt=media&token=736cab8d-c695-4d76-a2ac-87d2856287ae"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU7ES%2F776a68a3-dda9-4053-af6a-db49c5368e8e?alt=media&token=8c03991c-7c62-4f18-b0e7-a35024d25402"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU7ES%2F2de8908a-dbbf-42f6-b3a4-ba7f989d1c98?alt=media&token=bc5cd27c-93c1-41ac-a608-49ceccefe60e"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU7ES%2F01e7f71e-93d4-4e94-ae9e-9aa225ecc2ae?alt=media&token=fbb3c10b-5a01-4fca-8614-85af3ff49027"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU7ES%2F5066fc8a-fd87-4457-889b-c0df11c74c2a?alt=media&token=02922f0b-b5f0-4098-8e56-832cf1c0a933"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU7ES%2F3fedb024-32e0-4cc9-9ed0-90c0197a79ea?alt=media&token=4129f6bc-2ef8-470d-a81b-716f8a0d846f"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU7ES%2Fd15fefc2-5ed1-464b-bca4-f6940391bda6?alt=media&token=d7115420-d921-4f02-b7ac-813a11ef05fc"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
          

        </Slider>
      </>
      <div class="center"> 
      <Button href='https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU7ES%2FDIVU7ESMAT.pdf?alt=media&token=fc1188a7-d834-44cf-9f0b-da63a263e205' download className="btn-download-uno">Download resources</Button>
      </div>
  </Grid.Column>
  
  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
</Grid>
 
      // </div>
    );
  }
}
